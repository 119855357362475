import React from "react"
import { graphql } from "gatsby"
import teamStyles from "./team.module.scss"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Head from "../components/head"

export const query = graphql`
  query($slug: String!) {
    contentfulTeam(slug: { eq: $slug }) {
      id
      slug
      menu
      title
      body {
        json
      }
      mainMenu
      gallery {
        title
        id
        description
        localFile {
          childImageSharp {
            fixed(width: 128, height: 128, cropFocus: ATTENTION, fit: COVER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

const Team = props => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return (
          <h1 key={children} className="title has-text-centered">
            {children}
          </h1>
        )
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p className="has-text-centered subtitle">{children}</p>
      },
    },
  }
  return (
    <Layout>
      <Head title={props.data.contentfulTeam.title} />
      <section className="section">
        <div className="container">
          {documentToReactComponents(
            props.data.contentfulTeam.body.json,
            options
          )}
        </div>
      </section>
      <section className="section">
        <div className="container has-text-centered">
          <div className="columns is-multiline is-centered is-mobile">
            <div className="column is-9">
              <div className="columns">
                {props.data.contentfulTeam.gallery.map(pic => {
                  return (
                    <div key={pic.id} className="column">
                      <div className="level">
                        <div className="level-item">
                          <figure className="image is-128x128">
                            <Img
                              imgStyle={{ borderRadius: "290486px" }}
                              durationFadeIn={200}
                              fadeIn={true}
                              fixed={pic.localFile.childImageSharp.fixed}
                            />
                          </figure>
                        </div>
                      </div>
                      <h5 className="title is-5">{pic.title}</h5>
                      <p className="subtitle is-6">{pic.description}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Team
